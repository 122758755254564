<template>
  <div class="teams-activity-details">
    <div
      class="d-flex align-items-center justify-content-center"
      v-if="isLoading === true"
    >
      {{ $t("general.loading_ellipsis") }}
    </div>
    <div v-else>
      <Header
        :title="feedDetail.title"
        :iconPath="require('@/assets/images/icons/teams-message.svg')"
        :isThemedIcon="true"
        :themeColor="themeColor"
        :showBackButton="true"
      ></Header>
      <div class="teams-activity-details__body">
        <p class="teams-activity-details__description">
          {{ feedDetail.body }}
        </p>
        <p class="teams-activity-details__footer">
          {{
            $t("discussions.activity_feed.posted", {
              s: feedDetail.author,
              d: noOfDays
            })
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "./Shared/Header.vue";

import { getURL } from "@/store/modules/teams/actions.js";
export default {
  components: { Header },
  data() {
    return {
      isLoading: true,
      feedDetail: null
    };
  },
  computed: {
    themeColor() {
      return this.$route.query.themeColor;
    },
    noOfDays() {
      return this.$options.filters.humanize(this.feedDetail.updated_at);
    }
  },
  created() {
    this.getActivityFeedDetail();
  },
  methods: {
    getActivityFeedDetail() {
      this.isLoading = true;
      let headers = {
        headers: {
          Authorization: `Bearer ${this.$keycloak.token}`
        }
      };
      let obj = {
        feedId: this.$route.params.feedId
      };
      const URL = getURL(this.$store.getters.allConfig.baseURL, obj)
        .GET_FEED_DETAIL;
      axios
        .get(URL, headers)
        .then(response => {
          this.feedDetail = response.data.data;
          this.isLoading = false;
        })
        .catch(err => {
          this.$store.commit("SET_ERRORS", err.response.data);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.teams-activity-details {
  text-align: left;
  padding: 60px;

  .teams-activity-details__body {
    margin: 0 40px;
  }

  .teams-activity-details__description {
    color: #191c1d;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 60px 0 40px 0;
    word-break: break-word;
  }

  .teams-activity-details__footer {
    color: #44464e;
    font-size: 12px;
    line-height: 16px;

    span {
      font-weight: 500;
    }
  }
}
// rtl styles
[dir="rtl"] {
  .teams-activity-details {
    text-align: right;
  }
}
</style>
